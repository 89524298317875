const initialState = "null1"
  

const skinThemeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_SKIN_THEME':
        console.log(action.val)
        return action.val;
      default:
            return state
  }
}
  
  export default skinThemeReducer