const initialState = []


const clusterShortlistExpertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_SHORTLIST_EXPERTS':
      console.log("Updating:",action.val,state)
     return [
        ...state,action.val

     ]
    case 'HANDLE_SHORTLIST_EXPERTS_REMOVE':
      console.log("Deleting:",action.val,state)
   
     return  state.filter(expObj =>{ 
      if(expObj._id != action.val._id)
         return expObj;
    });

    case 'GET_SHORTLIST_DATA':
        console.log("Received Redux ShortlistedData:",action.allData,state)
        return action.allData.data;
    default:
          return state
}
}


 



export {clusterShortlistExpertsReducer};