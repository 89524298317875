// ** Initial State
const initialState = {
    data: [],
  }
  
  const expertFilterListReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_EXPERT_FILTER_DATA':
      //  console.log('loading new data')
        return {
          ...state,
          data: action.allData.data,
   
        }
      default:
        return state
    }
  }

  
  const initialState3 = {
    data: [],
  }
  
  const expertFilterUsersReducer = (state = initialState3, action) => {
    switch (action.type) {
      case 'HANDLE_FILTERED_EXPERTS_LIST':
      //  console.log('loading new data')
        return {
          ...state,
          data: action.val,
   
        }
      default:
        return state
    }
  }


  const filterState = {
   
  }
  
  const expertCurrentFilterReducer = (state = filterState, action) => {
    switch (action.type) {
      case 'HANDLE_CURRENT_EXPERTS_FILTERS':
      //  console.log('loading new data')
        return  action.val
   
      default:
        return state
    }
  }



  const isFilteringData = false
  
  const checkIsFilterReducer = (state = isFilteringData, action) => {
    switch (action.type) {
      case 'HANDLE_IS_APPLYING_FILTER':
      //  console.log('loading new data')
        return  action.val
   
      default:
        return state
    }
  }




  export {expertFilterUsersReducer , expertCurrentFilterReducer, checkIsFilterReducer}
  export default expertFilterListReducer