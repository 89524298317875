// ** Initial State
const initialState = {
    circlePackData: [],
  }
  
  const circlePackDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CIRCLE_PACK_DATA':
      //  console.log('loading new data')
        return {
          ...state,
          circlePackData: action.allData,
   
        }
      default:
        return state
    }
  }
  
  export default circlePackDataReducer