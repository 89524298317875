const initialState = "null"
  

const requestFormReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_REQUEST_FORM':
        console.log(action.val)
        return action.val;
      default:
            return state
  }
}
  
  export default requestFormReducer