const initialState = {}

const ExistingExpertReducer = (state = initialState, action) => {
    switch (action.type) {
      
      case 'GET_EXISTING_EXPERT_DATA':
           console.log("Received Redux ExistingData:",action.allData,state)
          return {
            ...state,
            expertData: action.allData,
     
          }
        default:
          return state
  }
  }

  export default ExistingExpertReducer;