// ** Initial State
const initialState = {
  vcData: [],
  vcDetailData: null,
  vcdataList: {},
  vcadminlist: {},
  uservclist:{},
  auditvclist:{},
}

const vcDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VCDATA':
      return {
        ...state,
        vcData: action.allData,

      }
    case 'GET_VCDETAILDATA':
      return {
        ...state,
        vcDetailData: action.allData,

      }

    case 'HANDLE_VCDATA_REMOVE':

      return {
        ...state,
        vcData: state.vcData.filter(expObj => expObj._id !== action.val),

      }

    case 'HANDLE_EDIT_VC':

      var data = state.vcData;
      var filterdata = data.map((item, i) => {
        if (item._id == action.val._id) {
          item = action.val;
        }
        return item;
      });


      return {
        ...state,
        vcData: filterdata
      };

    case 'GETALLVC':
      return {
        ...state,
        vcdataList: action.allData,
      };

    case 'GETADMINVC':
      return {
        ...state,
        vcadminlist: action.allData,
      }

    case 'GETUSERVC':
      return {
        ...state,
        uservclist: action.allData,
      }

      case 'GETAUDITVCLIST':
      return {
        ...state,
        auditvclist: action.allData,
        
      }

    default:
      return state
  }
}



export default vcDataReducer
