// ** Initial State
const initialState = {
    chartData: [],
  }
  
  const bubbleChartDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_BUBBLE_CHART_DATA':
      //  console.log('loading new data')
        return {
          ...state,
          chartData: action.allData,
   
        }
      default:
        return state
    }
  }
  
  export default bubbleChartDataReducer