const initialState = {}
  

const suggestionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_SUGGESTIONS':
        console.log(action.val)
        return action.val;
      default:
            return state
  }
}
  
  export default suggestionsReducer